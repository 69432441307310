import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getEvents } from 'services/hasura';

import { Home } from 'tutadoo-design-system/src/components/pages/Home';
import { useAuth0 } from '@auth0/auth0-react';
import Layout from 'components/Layout';
import * as R from 'ramda';
import HomePageAnimation from 'components/HomePageAnimation';
import { trackWindowScroll } from 'react-lazy-load-image-component';

import { ProgressiveImage } from '../ProgressiveImage';

function diff(a, b) {
  //  && new Date(end_date) >= new Date()
  return new Date(b.end_date).getTime() - new Date(a.end_date).getTime();
}

function home({ scrollPosition }) {
  const { isAuthenticated } = useAuth0();
  const now = new Date();
  const future = new Date();
  now.setMonth(now.getMonth() - 10);
  future.setMonth(now.getMonth() + 17);

  const [filter, setFilter] = useState<filters.Events>({
    filterSearchString: '%%',
    filterFrom: now.toISOString(),
    filterTo: future.toISOString(),
    filterLocation: { state: 'Nairobi', country: 'Kenya' },
    categories: '.',
    prices: '.',
    organizers: '.'
  });

  const [firstLoad, setFirstLoad] = useState(true);
  const { data, refetch, loading } = useQuery(getEvents(null, true), { variables: filter });
  const [selectedCategory, setCategory] = useState('.');
  const onSearch = ({
    searchString,
    dateRange
  }: {
    searchString: string;
    dateRange: string[];
    location;
  }) => {
    setFilter({
      filterSearchString: `%${searchString}%`,
      filterFrom: dateRange[0],
      filterTo: dateRange[1],
      filterLocation: { state: 'Nairobi', country: 'Kenya' },
      categories: selectedCategory ? [selectedCategory] : '.',
      prices: '.',
      organizers: '.'
    });
  };

  const onFilterCategory = (c) => {
    if (!c) return;
    setCategory(c.value);
  };

  useEffect(() => {
    refetch(filter);
  }, [filter]);
  useEffect(() => {
    if (firstLoad && !loading) {
      setFirstLoad(false);
    }
  }, [firstLoad, loading]);

  return (
    <Layout key="home" noPadding>
      {firstLoad ? (
        <div className="flex justify-center items-center py-10">
          <HomePageAnimation />
        </div>
      ) : (
        <Home
          ImageComponent={(props) => (
            <ProgressiveImage scrollPosition={scrollPosition} {...props} />
          )}
          pageSections={data?.sections}
          events={
            data?.events
              ? R.sort(
                  diff,
                  data?.events.filter(({ status, category }) => {
                    return (
                      ['soldout', 'published'].includes(status) &&
                      category.includes(selectedCategory)
                    );
                  })
                )
              : []
          }
          categoryListProps={{
            max: 6,
            categories: data?.categories
              ? data.categories.map(({ label, value }) => ({ label, value }))
              : [],
            onCategory: onFilterCategory
          }}
          loading={loading}
          basePath=""
          onSearch={onSearch}
          authenticated={isAuthenticated}
        />
      )}
    </Layout>
  );
}

export default trackWindowScroll(home);
